import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import SiteLayout from '../../Layout/SiteLayout';

import { AuthSiteLayout } from '../../Layout/AuthSiteLayout';
import { MobileComponent } from '../../styles/commonComponentStyle/layoutStyles';
import { getUserInfoSSR } from '../../utils/cf';

const ResetPassword = () => {
  return (
    <>
      <MobileComponent>
        <SiteLayout>
          <ResetPasswordForm />
        </SiteLayout>
      </MobileComponent>
      <AuthSiteLayout>
        <ResetPasswordForm />
      </AuthSiteLayout>
    </>
  );
};

export default ResetPassword;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
